var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("span", { staticClass: "secondary--text font-weight-bold" }, [
          _vm._v("User profile"),
        ]),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("Name")])],
                1
              ),
              _c("v-col", { attrs: { cols: "8" } }, [
                _c("span", { staticClass: "secondary--text subtitle-2" }, [
                  _vm._v(_vm._s(_vm.userInfo.given_name)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("Surname")])],
                1
              ),
              _c("v-col", { attrs: { cols: "8" } }, [
                _c("span", { staticClass: "secondary--text subtitle-2" }, [
                  _vm._v(_vm._s(_vm.userInfo.family_name)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("Username")])],
                1
              ),
              _c("v-col", { attrs: { cols: "8" } }, [
                _c("span", { staticClass: "secondary--text subtitle-2" }, [
                  _vm._v(_vm._s(_vm.userInfo.username)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("Email")])],
                1
              ),
              _c("v-col", { attrs: { cols: "8" } }, [
                _c("span", { staticClass: "secondary--text subtitle-2" }, [
                  _vm._v(_vm._s(_vm.userInfo.email)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("User status")])],
                1
              ),
              _c("v-col", { attrs: { cols: "8" } }, [
                _c("span", { staticClass: "secondary--text subtitle-2" }, [
                  _vm._v(
                    _vm._s(_vm.userInfo.is_active ? "Active" : "Inactive")
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c("v-divider", { staticClass: "my-4" }),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("Newsletter")])],
                1
              ),
              _c("v-col", { attrs: { cols: "8" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("v-checkbox", {
                      attrs: {
                        disabled: _vm.fetchingUserInfo,
                        label: "Signed up for newsletter",
                      },
                      model: {
                        value: _vm.newsletter,
                        callback: function ($$v) {
                          _vm.newsletter = $$v
                        },
                        expression: "newsletter",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v("Analytics")])],
                1
              ),
              _c("v-col", { attrs: { cols: "8" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("v-checkbox", {
                      attrs: {
                        disabled: _vm.fetchingUserInfo,
                        label: "Allow use of analytics data",
                      },
                      on: { click: _vm.confirm },
                      model: {
                        value: _vm.analyticsOptIn,
                        callback: function ($$v) {
                          _vm.analyticsOptIn = $$v
                        },
                        expression: "analyticsOptIn",
                      },
                    }),
                  ],
                  1
                ),
                !_vm.isProduction()
                  ? _c("p", [
                      _vm._v(
                        "This is non production so analytics is always off."
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { offset: "4" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { color: "primary" }, on: { click: _vm.save } },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "my-4" }),
          _vm.userInfo.is_sysadmin
            ? _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [_c("v-subheader", [_vm._v("System Administrator")])],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            to: { name: "admin-overview" },
                            depressed: "",
                          },
                        },
                        [_vm._v("manage")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.userInfo.is_sysadmin
            ? _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [_c("v-subheader", [_vm._v("Delete account")])],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [_c("AccountDeleteDialog")],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }